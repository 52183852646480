/*********************Default-CSS*********************/
:root {
  scroll-behavior: unset;
}

a,
span,
a:hover,
a:active,
button {
  text-decoration: none;
  color: unset;
}

*:focus::placeholder {
  opacity: 0;
}

::placeholder {
  opacity: 1;
  transition: opacity .4s;
}

a,
div a:hover,
div a:active,
div a:focus,
button {
  text-decoration: none;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -ms-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

p {
  margin: 0;
}

body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  margin: 0;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

.cm_overflow {
  overflow: hidden;
  touch-action: unset;
}

::selection {
  color: #fff;
  background-color: #e693a7;
}

.main_wrapper {
  overflow: hidden;
}

/*********************  Default-CSS  End *********************/
/* ----Date-picker-css----- */
.MuiPickersPopper-root{
z-index: 1350;
}
.MuiPickersPopper-root .MuiYearPicker-root::-webkit-scrollbar {
  width: 10px;
}
.MuiPickersPopper-root .MuiYearPicker-root::-webkit-scrollbar-track {
  background-color: #e31e4d;
}
.MuiPickersPopper-root .MuiYearPicker-root::-webkit-scrollbar-thumb {
  background-color: #e31e4d;
}
.MuiPickersPopper-root .MuiYearPicker-root::-webkit-scrollbar-thumb:hover {
  background-color: #e31e4d;
}
.MuiPickersPopper-root .MuiCalendarOrClockPicker-root  > div , .MuiPickersPopper-root .MuiCalendarOrClockPicker-root  > div .MuiCalendarPicker-root ,
.MuiPickersPopper-root .MuiCalendarOrClockPicker-root  > div .MuiCalendarPicker-root .MuiMonthPicker-root {
width: 300px;
}
.MuiPickersPopper-root .MuiCalendarOrClockPicker-root  > div .MuiCalendarPicker-root .MuiMonthPicker-root {
  margin: 0!important;
  padding: 0 10px;
}
.MuiPickersPopper-root .MuiCalendarOrClockPicker-root  > div .MuiCalendarPicker-root .PrivatePickersYear-root button {
  width: 70px;
  font-size: 0.9rem;
}
.MuiPickersPopper-root .MuiCalendarOrClockPicker-root  > div .MuiMonthPicker-root button {
  font-size: 0.9rem;
}

.loadbalancer-charginstation_dialog+.MuiPopover-root.MuiMenu-root {
  z-index: 1304;
}
.MuiPopover-root.sidemenu_bar+.MuiPopover-root.MuiMenu-root .MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root:hover,
.loadbalancer-charginstation_dialog+.MuiPopover-root.MuiMenu-root .MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root:hover {
  background-color: #e31e4d;
  border-left-color: #e31e4d;
}

.MuiPopover-root.sidemenu_bar+.MuiPopover-root.MuiMenu-root .MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root.Mui-selected,
.loadbalancer-charginstation_dialog+.MuiPopover-root.MuiMenu-root .MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root.Mui-selected {
  color: #FFFFFF;
  background-color: #e31e4d;
  text-transform: capitalize;
  border-left-color: #e31e4d;
}

.MuiPopover-root.sidemenu_bar+.MuiPopover-root.MuiMenu-root .MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root,
.loadbalancer-charginstation_dialog+.MuiPopover-root.MuiMenu-root .MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root {
  text-transform: capitalize;
  border-left: 3px solid transparent;
}

.MuiPopover-root.sidemenu_bar+.MuiPopover-root.MuiMenu-root .MuiPaper-root,
.loadbalancer-charginstation_dialog+.MuiPopover-root.MuiMenu-root .MuiPaper-root {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.switch_btn .MuiSwitch-root.MuiSwitch-sizeMedium {
  margin: 0;
  padding: 2px;
  background-color: #fff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  width: 50px;
  height: 30px;
}

.switch_btn .MuiSwitch-input+span {
  color: #e31e4d;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.14);
}

.switch_btn .Mui-checked.MuiSwitch-switchBase .MuiSwitch-thumb {
  color: #fff;
}

.switch_btn .MuiSwitch-root .MuiSwitch-switchBase {
  margin: 3px 0 0 0;
}

.switch_btn .Mui-checked.MuiSwitch-switchBase {
  margin: 3px;
}

.switch_btn .MuiSwitch-switchBase.MuiSwitch-colorPrimary.MuiButtonBase-root {
  top: 1px;
  left: 4px;
}

.switch_btn .MuiSwitch-switchBase.MuiSwitch-colorPrimary.MuiButtonBase-root.Mui-checked {
  top: 1px;
  left: 5px;
}

/* -----slider----- */
.settingpage-Slider_block .MuiSlider-rail {
  background: #d5dfdc;
  border-radius: 8px;
  height: 8px;
}

.settingpage-Slider_block .MuiSlider-track {
  background: linear-gradient(180deg, #e31e4d 0%, #e31e4d 100%);
  border-radius: 8px;
}

.settingpage-Slider_block .css-1tfve6q-MuiSlider-mark {
  opacity: 0;
}

.settingpage-Slider_block .MuiSlider-markLabel {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  top: 42px;
  color: #646464;
}

.settingpage-Slider_block .MuiSlider-markLabel.MuiSlider-markLabelActive,
.settingpage-Slider_block .MuiSlider-markLabel.MuiSlider-markLabelActive {
  margin-left: 12px;
}

.settingpage-Slider_block .MuiSlider-colorPrimary span:nth-child(6).MuiSlider-markLabel,
.settingpage-Slider_block .MuiSlider-colorPrimary span:nth-child(6).MuiSlider-markLabel {
  left: 96% !important;
  margin-left: unset !important;
}

.settingpage-Slider_block .MuiSlider-valueLabelCircle {
  line-height: 1.5;
}

/* ----pv_page-- */
.PVSettings-PVsurplus_card_body .MuiSlider-markLabel.MuiSlider-markLabelActive,
.PVSettings-PVsurplus_card_body .MuiSlider-markLabel.MuiSlider-markLabelActive {
  margin-left: 30px;
}

.PVSettings-PVsurplus_card_body .MuiSlider-colorPrimary span:nth-child(6).MuiSlider-markLabel,
.PVSettings-PVsurplus_card_body .MuiSlider-colorPrimary span:nth-child(6).MuiSlider-markLabel {
  left: calc(100% - 30px) !important;
}
